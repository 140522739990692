<template>
  <v-card class="pa-4" flat>
    <div>
      <div class="d-flex flex-wrap">
        <v-icon class="mr-3" @click="$router.push({ name: 'Extracurricular' })">
          mdi-keyboard-backspace
        </v-icon>
        <div class="d-flex align-center">
          <v-autocomplete
            v-model="idClass"
            :items="dataClass"
            :loading="loadingClass"
            :label="$t('extracuricular.by_class')"
            :item-text="item => className(item)"
            item-value="id"
            hide-details
            outlined
            dense
            @change="changeClass"
          />
          <div class="d-inline font-weight-bold subtitle-2 ml-3">
            {{ total }} {{ $t("routes.student") }}
          </div>
        </div>
      </div>

      <v-divider class="my-4"></v-divider>

      <v-data-table
        v-model="multipleId"
        :headers="tableHeaders"
        :items="dataMember"
        :loading="loading"
        item-key="eskul_id"
        hide-default-footer
        show-select
        class="elevation-0"
      >
        <template v-slot:item.action="{ item }">
          <v-tooltip left>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                icon
                small
                dark
                class="primary mr-2"
                :disabled="multipleId.length > 0"
                @click="
                  showConfirmAccept([
                    { extra: item.eskul_id, student: item.id }
                  ])
                "
              >
                <v-icon small>mdi-check</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("master_data.student.accept") }}</span>
          </v-tooltip>
          <v-tooltip left>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                icon
                small
                dark
                class="error"
                @click="
                  toggleDialogRejected([
                    { extra: item.eskul_id, student: item.id }
                  ])
                "
                :disabled="multipleId.length > 0"
              >
                <v-icon small>mdi-close</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("master_data.student.decline") }}</span>
          </v-tooltip>
        </template>
      </v-data-table>

      <Pagination
        :length="length"
        :total="total"
        :current-page="page"
        :limit="limit"
      />

      <v-speed-dial
        v-if="multipleId.length > 0"
        v-model="multiple"
        bottom
        right
        direction="top"
        open-on-hover
        style="position: absolute"
      >
        <template v-slot:activator>
          <v-btn v-model="multiple" color="blue darken-2" dark fab>
            <v-icon>mdi-menu-up</v-icon>
          </v-btn>
        </template>
        <v-tooltip left>
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              fab
              icon
              small
              dark
              class="primary mr-2"
              @click="showConfirmAccept"
            >
              <v-icon>mdi-check</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("master_data.student.accept") }}</span>
        </v-tooltip>
        <v-tooltip left>
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              fab
              icon
              small
              dark
              class="error"
              @click="toggleDialogRejected"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("master_data.student.decline") }}</span>
        </v-tooltip>
      </v-speed-dial>
      <Rejected
        :is-dialog="isDialogRejected"
        :loading="loadingReject"
        @close-dialog="toggleDialogRejected"
        @value="v => (paramDecline.reject_reason = v)"
        @save="decline"
      />
      <ModalConfirm
        :content="$t('master_data.student.dialog.content_1')"
        :close="() => (this.confirmAccept = false)"
        :isOpen="confirmAccept"
        :save="accept"
        :loading="loadingConfirm"
      />
    </div>
  </v-card>
</template>

<script>
import {
  memberRequestList,
  acceptMemberBulk,
  removeMemberBulk
} from "@/api/admin/academic/extracurricular";
import { class_list } from "@/api/admin/academic/classList";
import { get_school_year_list } from "@/api/admin/schoolClass";

export default {
  components: {
    Pagination: () => import("@/components/Pagination"),
    Rejected: () => import("./components/Rejected"),
    ModalConfirm: () => import("@/components/ModalConfirm")
  },
  created() {
    this.initData();
  },
  data() {
    return {
      bodyClass: {
        filter: {
          "classroom.school_year_master": []
        },
        sort: "class_masters.id",
        order: "ASC"
      },
      isDialogRejected: false,
      loadingReject: false,
      loadingConfirm: false,
      idClass: "",
      dataClass: [],
      bodyExtra: { filter: { classroom: [] } },
      tableHeaders: [
        {
          text: this.$i18n.t("app.name"),
          align: "left",
          value: "name"
        },
        { text: "NIS", value: "nis" },
        { text: this.$i18n.t("app.class"), value: "kelas" },
        { text: "Eskul", value: "eskul" },
        {
          text: this.$i18n.t("app.action"),
          sortable: false,
          value: "action",
          width: 150
        }
      ],
      length: 0,
      total: 0,
      limit: 10,
      page: 1,
      loading: false,
      confirmAccept: false,
      dataMember: [],
      multipleId: [],
      multiple: false,
      paramAccept: { data: [] },
      paramDecline: { reject_reason: "", data: [] },
      loadingClass: false
    };
  },
  methods: {
    className: (item, separator) =>
      `${item.grade} ${separator || "/"} ${item.name}`,
    schoolYear() {
      this.loadingClass = true;
      get_school_year_list(false)
        .then(res => {
          let r = res.data.data;
          let itemSchoolYear = r.find(d => d.status == 1);
          if (itemSchoolYear) {
            this.bodyClass.filter["classroom.school_year_master"] = [
              itemSchoolYear.school_year_master
            ];
            this.classes();
          }
        })
        .finally(() => (this.loadingClass = false));
    },
    classes() {
      this.loadingClass = true;
      class_list(this.bodyClass)
        .then(res => {
          this.dataClass = res.data.data;
        })
        .finally(() => (this.loadingClass = false));
    },
    changeClass(idClass) {
      this.bodyExtra.filter.classroom = [idClass];
      this.getMemberRequest();
    },
    async getMemberRequest() {
      this.loading = true;
      const response = await memberRequestList(this.bodyExtra);
      if (response.data.code) {
        const data = response.data.data;
        this.dataMember = data.data;
        this.length = data.last_page;
        this.total = data.total;
        this.page = data.current_page;
      } else {
        this.snackBar(false, response.data.message);
      }
      this.loading = false;
    },
    toggleDialogRejected(item) {
      if (item != null) this.paramDecline.data = item;
      this.isDialogRejected = !this.isDialogRejected;
    },
    initData() {
      this.multipleId = [];
      this.paramAccept.data = [];
      this.paramAccept.data = [];
      this.paramAccept.reject_reason = "";
      this.getMemberRequest();
      this.schoolYear();
    },
    showConfirmAccept(singleData) {
      this.confirmAccept = true;
      this.paramAccept.data =
        this.multipleId.length == 0
          ? singleData
          : this.multipleId.map(d => ({
              extra: d.eskul_id,
              student: d.id
            }));
    },
    snackBar(isSuccess, msg) {
      this.$store.commit("CALL_SNACKBAR", {
        msg: msg,
        color: isSuccess ? "success" : "error"
      });
    },
    async accept() {
      this.loadingConfirm = true;
      try {
        const response = await acceptMemberBulk(this.paramAccept);
        if (response.data.code) {
          this.snackBar(true, this.$i18n.t("extracuricular.msg.accept_member"));
          this.initData();
        } else {
          this.snackBar(true, response.data.message);
        }
      } catch (error) {
        this.loadingConfirm = false;
        console.error("accept()\n", error);
      }
      this.confirmAccept = false;
      this.loadingConfirm = false;
    },
    async decline() {
      this.loadingReject = true;
      if (this.multipleId.length != 0) {
        this.paramDecline.data = this.multipleId.map(d => ({
          extra: d.eskul_id,
          student: d.id
        }));
      }
      try {
        const response = await removeMemberBulk(this.paramDecline);
        if (response.data.code) {
          this.snackBar(true, this.$i18n.t("extracuricular.msg.reject_member"));
        } else {
          this.snackBar(true, response.data.message);
        }
      } catch (error) {
        console.error("decline()\n", error);
        this.loadingReject = false;
      }
      this.loadingReject = false;
      this.toggleDialogRejected();
      this.initData();
    }
  }
};
</script>
