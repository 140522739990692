import request from "@/utils/request";

export function getListExt(data) {
  return request({
    method: "post",
    url: "extra_curricular/list",
    data: data
  });
}

export function postMasterExt(data) {
  return request({
    method: "post",
    url: "extra_curricular/add_master",
    data: data
  });
}

export function getMasterExt() {
  return request({
    method: "post",
    url: "extra_curricular/master"
  });
}

export function updateMasterExt(data) {
  return request({
    method: "post",
    url: "extra_curricular/update_master",
    data: data
  });
}

export function deleteMasterExt(data) {
  return request({
    method: "post",
    url: "extra_curricular/delete_master",
    data: data
  });
}

export function memberRequest(data) {
  return request({
    method: "post",
    url: "extra_curricular/member_request",
    data: data
  });
}

export function listTeacher() {
  return request({
    method: "post",
    url: "extra_curricular/list_teacher"
  });
}

export function addExtra(data) {
  return request({
    method: "post",
    url: "extra_curricular/add",
    data: data
  });
}

export function listMember(data) {
  return request({
    method: "post",
    url: "extra_curricular/members",
    data: data
  });
}

export function fetchByIdExtra(data) {
  return request({
    method: "post",
    url: "extra_curricular/extra_byid",
    data: data
  });
}

export function memberRequestList(data) {
  return request({
    method: "post",
    url: "extra_curricular/member_request_list",
    data: data
  });
}

export function acceptMember(data) {
  return request({
    method: "post",
    url: "extra_curricular/accept_member",
    data: data
  });
}

export function declineMember(data) {
  return request({
    method: "post",
    url: "extra_curricular/remove_member",
    data: data
  });
}

export function updateExtra(data) {
  return request({
    method: "post",
    url: "extra_curricular/update",
    data: data
  });
}

export function acceptMemberBulk(data) {
  return request({
    method: "post",
    url: "extra_curricular/accept_member_bulk",
    data: data
  });
}

export function removeMemberBulk(data) {
  return request({
    method: "post",
    url: "extra_curricular/remove_member_bulk",
    data: data
  });
}

export function inputMember(data) {
  return request({
    method: "post",
    url: "extra_curricular/input_member",
    data: data
  });
}

export function studentList(data) {
  return request({
    method: "post",
    url: "extra_curricular/student-list",
    data: data
  });
}
